import * as i from 'types';

import { isSameOrAfterToday } from 'services';

export const generateBookingLabel = (item: i.Class) => {
  const availableSpots = (item.attendance?.spotCount || 0) - (item.attendance?.enrolled || 0);
  let label: LabelType = {
    backgroundColor: 'primaryLight',
    text: '',
  };

  if (item.event) {
    label = { text: 'Event', backgroundColor: 'primary' };
  }

  if (availableSpots < 6 && availableSpots > 0) {
    label = {
      text: `${availableSpots} spot${availableSpots > 1 ? 's' : ''} left`,
      backgroundColor: 'primaryLight',
    };
  }

  if (item.full && !item.waitlistFull) {
    label = { text: 'Waiting list', backgroundColor: 'gray' };
  }

  if (item.full && item.waitlistFull) {
    label = { text: 'Sold out', backgroundColor: 'red' };
  }

  if (item.isBooked && !item.isWaitlisted && !item.isWaitlistExpired) {
    label = {
      text: isSameOrAfterToday(item.classDate) ? 'Booked' : 'Completed',
      backgroundColor: 'special',
    };
  }

  if (item.isBooked && item.isWaitlisted && !item.isWaitlistExpired) {
    label = { text: 'On waitlist', backgroundColor: 'gray' };
  }

  if (item.isBooked && item.isWaitlisted && item.isWaitlistExpired) {
    label = { text: 'Waitlist expired', backgroundColor: 'gray' };
  }

  return label.text ? label : undefined;
};

export const generateClassesLabel = (item: i.Serie) => {
  let label: LabelType = {
    backgroundColor: 'primaryLight',
    text: '',
  };

  switch (item.status) {
    case 'Booked':
      label = { text: 'Booked', backgroundColor: ['green', '500'], textColor: 'white' };
      break;
    case 'Waitlisted':
      label = { text: 'On waitlist', backgroundColor: 'gray', textColor: 'white' };
      break;
    case 'Waitlist Expired':
      label = { text: 'Waitlist Expired', backgroundColor: 'gray', textColor: 'white' };
      break;
    case 'Enrolled':
      const text = isSameOrAfterToday(item.classDate) ? 'Booked' : 'Completed';
      label = { text, backgroundColor: ['green', '500'] };
      break;
    case 'Cancelled':
      label = { text: 'Cancelled', backgroundColor: 'primary' };
      break;
    case 'No Show':
      label = { text: 'No Show', backgroundColor: 'gray' };
      break;
  }

  return label;
};

type LabelType = {
  backgroundColor: i.LabelColors;
  text: string;
  textColor?: i.TextColors;
};
